<template>
  <div class="join">
    <CircleLoader :ratio="1.5"/>
    <h1>One second,</h1>
    <p>you will be redirected to the workspace after the request is confirmed</p>
  </div>
</template>

<script>
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import {getInCollaboratorsRequestApi} from "@/api/workspace";
import {useRouter} from "vue-router";
import {checkAuth} from "@/setups/authSetup";
import {inject} from "vue";

export default {
  name: "JoinToWorkspace",
  components: {CircleLoader},
  props: {
    workspaceId: {
      type: String,
      required: true
    },
    workspaceKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const getWorkspaces = inject("getWorkspaces")

    if (checkAuth()) {
      if (localStorage.getItem("collaborationData") !== null)
        localStorage.removeItem("collaborationData")

      getInCollaboratorsRequestApi(props.workspaceId, props.workspaceKey).then(() => {
        getWorkspaces()
        router.push({
          name: "Dashboard",
          params: {
            workspaceId: props.workspaceId
          }
        })
      })
    } else {
      localStorage.setItem("collaborationData", JSON.stringify({
        workspaceId: props.workspaceId,
        workspaceKey: props.workspaceKey
      }))
      router.push({
        name: "Auth"
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.join {
  padding-top: 40px;
  background-color: $pxp-gray-accent-color-dimmer;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

</style>